export const data = [
  {
    title: "Website Development",
    subtitle: "Engineering",
    description:
      'Phosfluorescently engage worldwide methodologies with web-enabled technology. Interactively coordinate proactive e-commerce via process-centric "outside the box" thinking. Completely pursue scalable customer service through sustainable potentialities. Objectively innovate empowered manufactured products whereas parallel platforms.',
  },
  {
    title: "Website Design",
    subtitle: "Creative Solutions",
    description:
      "Craft visually stunning and user-friendly websites that capture your brand essence. Our design approach blends creativity with functionality to deliver a compelling online presence tailored to your business needs.",
  },
  {
    title: "Logo Design",
    subtitle: "Brand Identity",
    description:
      "Establish a strong and memorable brand identity with our custom logo design services. Our creative team works closely with you to create a distinctive logo that reflects your values, vision, and uniqueness.",
  },
  {
    title: "SEO",
    subtitle: "Search Engine Optimization",
    description:
      "Optimize your website for search engines and improve your online visibility. Our SEO experts employ strategic techniques to enhance your website's ranking, driving targeted traffic and increasing your digital footprint.",
  },
  {
    title: "Publishing website",
    subtitle: "Online Presence",
    description:
      "Take your content online with our publishing website services. Whether you're a blogger, author, or content creator, we provide platforms to showcase your work and engage with your audience effectively.",
  },
  {
    title: "Support / Maintenance",
    subtitle: "Reliable Assistance",
    description:
      "Ensure the smooth operation of your website with our dedicated support and maintenance services. Our team is ready to address any issues, implement updates, and provide ongoing assistance to keep your site running seamlessly.",
  },
];
