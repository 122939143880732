import styled from "@emotion/styled";
import { breakpointMax, calcRem, colors } from "../../../theme";
import { keyframes } from "@emotion/react";

const floatInAnimationTB = keyframes`
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
    opacity:1
  }
`;
export const StyledContactUs = styled("div")({
  height: calcRem(300),
  backgroundColor: "transparent",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  position: "relative",

  [breakpointMax("xxl")]: {
    height: calcRem(250),
  },
  [breakpointMax("sm")]: {
    height: calcRem(200),
  },
});

export const StyledContactUsTitle = styled("h3")({
  color: colors.white,
  fontSize: calcRem(48),
  fontWeight: "bold",
  textAlign: "center",
  zIndex: 2,
  margin: `${calcRem(50)} 0 0`,
  animation: `${floatInAnimationTB} .5s ease forwards .5s`,
  opacity: 0,
  [breakpointMax("xxxl")]: {},
  [breakpointMax("xxl")]: {
    fontWeight: "600",
    fontSize: calcRem(40),
    margin: `${calcRem(30)} 0 0`,
  },
  [breakpointMax("lg")]: {},
  [breakpointMax("md")]: {
    fontSize: calcRem(36),
  },
  [breakpointMax("sm")]: {
    fontSize: calcRem(28),
  },
  [breakpointMax("xs")]: {},
  [breakpointMax("xxs")]: {
    fontSize: calcRem(26),
  },
});
export const StyledContactUsDes = styled("p")({
  color: colors.white,
  fontSize: calcRem(18),
  fontWeight: "300",
  zIndex: 2,
  marginTop: calcRem(10),
  animation: `${floatInAnimationTB} .5s ease forwards .75s`,
  opacity: 0,
  [breakpointMax("xxl")]: {
    fontSize: calcRem(16),
  },
  [breakpointMax("md")]: {
    fontSize: calcRem(14),
    marginTop: calcRem(6),
  },
  [breakpointMax("xs")]: {
    fontSize: calcRem(13),
  },
});

export const StyledInputWrapper = styled("div")({
  backgroundColor: colors.white,
  borderRadius: calcRem(100),
  padding: calcRem(5),
  position: "relative",
  height: calcRem(50),
  maxWidth: calcRem(700),
  width: "40%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: calcRem(30),
  animation: `${floatInAnimationTB} .5s ease forwards 1s`,
  opacity: 0,
  [breakpointMax("xxl")]: {
    height: calcRem(40),
  },
  [breakpointMax("lg")]: {
    width: "50%",
  },
  [breakpointMax("md")]: {
    width: "60%",
  },
  [breakpointMax("sm")]: {
    width: "80%",
    height: calcRem(40),
  },
  [breakpointMax("xs")]: {
    width: "90%",
    height: calcRem(35),
  },

  "& form": {
    width: "100%",
  },
});

export const StyledInput = styled("input")({
  backgroundColor: "transparent",
  border: "none",
  padding: calcRem(5),
  fontSize: calcRem(16),
  color: colors.light,
  width: "80%",
  marginLeft: calcRem(10),
  "&:focus": { outline: "none" },
  textTransform: "lowercase",
  [breakpointMax("xs")]: {
    fontSize: calcRem(14),
  },
});

export const StyledInputIcon = styled("img")({
  height: "90%",
  "&:hover": { cursor: "pointer" },
});
