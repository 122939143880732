import styled from "@emotion/styled";
import { breakpointMax, calcRem, colors } from "../../theme";
import { keyframes } from "@emotion/react";

const floatInTop = keyframes`
  0% {
    transform: translateY(-10px);
    opacity:0
  }
  100% {
    transform: translateY(0px);
    opacity:1

  }
`;
export const StyledNavigation = styled("div")({
  minHeight: "60px",
  color: colors.black,
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  marginTop: calcRem(40),
  [breakpointMax("xxl")]: {
    marginTop: calcRem(20),
  },
  [breakpointMax("xs")]: {
    marginTop: calcRem(10),
  },
});

export const StyledNavigationLogo = styled("img")({
  height: "80px",
  margin: "auto 0",
  animation: `${floatInTop} 1s ease forwards 2s`, 
  opacity: 0,
  [breakpointMax("xxl")]: {
    height: "70px",
  },
  [breakpointMax("md")]: {
    height: "50px",
  },
  [breakpointMax("xs")]: {
    height: "40px",
  },
});

export const StyledMenu = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignContent: "center",
});
interface StyledMenuItemProps {
  active?: boolean;
}

export const StyledMenuItem = styled("div")<StyledMenuItemProps>(
  ({ active }) => [
    {
      margin: `auto ${calcRem(12)}`,
      color: active ? colors.primary : colors.accent,
      transitionDuration: ".3s",
      fontWeight: "500",
      "&:hover": {
        cursor: "pointer",
        color: colors.primary,
      },
    },
  ]
);

export const StyledButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  backgroundColor: colors.primary,
  width: "180px",
  height: "50px",
  margin: "auto 0",
  borderRadius: "100px",
  "&:hover": {
    cursor: "pointer",
  },
});

export const StyledButtonIcon = styled("img")({
  height: "20px",
  margin: `auto 0`,
});

export const StyledButtonText = styled("p")({
  marginLeft: calcRem(10),
  color: colors.white,
  fontWeight: "bold",
});
