import React, { useRef, useState } from "react";
import {
  StyledContactUs,
  StyledContactUsTitle,
  StyledInputWrapper,
  StyledContactUsDes,
  StyledInput,
  StyledInputIcon,
} from "./ContactUs.styled";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const sendIcon = require("./assets/sendIcon.png");

interface ContactUsProps {
  setIsVisibleModal?: any;
}

export const ContactUs = ({ setIsVisibleModal }: ContactUsProps) => {
  const [email, setEmail] = useState("");
  const form: React.RefObject<HTMLFormElement> = useRef(null); // Specify the type

  const validateForm = () => {
    let isValid = true;

    if (!/^\S+@\S+\.\S+$/.test(email)) {
      isValid = false;
    }

    return isValid;
  };
  const notify = ({
    isSuccess = false,
    isEmailValid = true,
    message = "",
  } = {}) => {
    const toastFunc = isSuccess ? toast.success : toast.error;
    const defaultMessage = isSuccess
      ? "Message sent!"
      : "Something went wrong!";
    const errorMessage = isEmailValid
      ? defaultMessage
      : "Please enter a valid email address.";

    toastFunc(message || errorMessage, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const handleSend = (e: React.FormEvent) => {
    e.preventDefault();

    if (validateForm() && form.current) {
      emailjs
        .sendForm(
          "service_x60c4t6",
          "template_0tbm5cw",
          form.current,
          "4gQd5O5yFt4RXVYLt"
        )
        .then(
          () => {
            setEmail("");
            setIsVisibleModal(true);
          },
          () => {
            notify({ isSuccess: false });
          }
        );
    } else {
      notify({ isSuccess: false, isEmailValid: false });
    }
  };

  return (
    <StyledContactUs>
      <StyledContactUsTitle>Contact us</StyledContactUsTitle>
      <StyledContactUsDes>
        Leave us your mail and we will contact you!
      </StyledContactUsDes>
      <StyledInputWrapper>
        <form ref={form}>
          <StyledInput
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
            placeholder="Enter your email"
            name="email"
          />
        </form>
        <StyledInputIcon
          onClick={handleSend}
          src={sendIcon}
          alt="PUT SEO HERE"
        />
      </StyledInputWrapper>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </StyledContactUs>
  );
};
