import {
  StyledOffers,
  StyledOffersContent,
  StyledOffersLeft,
  StyledOffersRight,
  StyledOffersImg,
  StyledOffersTitle,
  StyledOfferContact,
  StyledOffersDes,
} from "./Offers.styled";
import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

const offer = require("./assets/offer-image.png");
const offerPhone = require("./assets/offer-phone.png");
const offerMail = require("./assets/offer-mail.png");

export const Offers = () => {
  const [offerVisible, setOfferVisible] = useState(false);
  const [offerObserverRef, offerInView] = useInView({
    threshold: 0.5,
  });
  useEffect(() => {
    if (offerInView && !offerVisible) {
      setOfferVisible(true);
    }
  }, [offerInView, offerVisible]);

  return (
    <StyledOffers ref={offerObserverRef}>
      {offerVisible && (
        <StyledOffersContent>
          <StyledOffersLeft>
            <StyledOffersTitle>MORE PRICING PLANS</StyledOffersTitle>
            <StyledOffersDes>
              We offer bespoke plans tailored to your needs
            </StyledOffersDes>
            <StyledOfferContact>
              <p>Contact us for a personalized solution</p>
              <div>
                <div>
                  <img src={offerPhone} alt="PUT SEO HERE" />
                  <p>+38169718111</p>
                </div>
                <div>
                  <img src={offerMail} alt="PUT SEO HERE" />
                  <p>ppavle@live.com</p>
                </div>
              </div>
            </StyledOfferContact>
          </StyledOffersLeft>
          <StyledOffersRight>
            <StyledOffersImg src={offer} alt="PUT SEO HERE" />
          </StyledOffersRight>
        </StyledOffersContent>
      )}
    </StyledOffers>
  );
};
