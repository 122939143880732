import styled from "@emotion/styled";
import { colors } from "../../theme";
import { keyframes } from "@emotion/react";
const showIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
export const StyledLoader = styled("div")({
  minHeight: "100vh",
  width: "100%",
  backgroundColor: colors.white,
  color: colors.black,
  position: "fixed",
  left: 0,
  top: 0,
  zIndex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  animation: `${showIn} 1s ease forwards 0s `,
  opacity: 0,
});
