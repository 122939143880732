import {
  StyledFooter,
  StyledCopyright,
  StyledCopyrightText,
} from "./Footer.styled";
import { ContactUs } from "../../components";
import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

interface FooterProps {
  forwardRef: React.RefObject<HTMLDivElement>;
  setIsVisibleModal: any;
}
export const Footer = ({ forwardRef, setIsVisibleModal }: FooterProps) => {
  const [footerVisible, setFooterVisible] = useState(false);

  const [footerObserverRef, footerInView] = useInView({
    threshold: 0.1,
  });
  useEffect(() => {
    if (footerInView && !footerVisible) {
      setTimeout(() => {
        setFooterVisible(true);
      }, 200);
    }
  }, [footerInView, footerVisible]);

  return (
    <StyledFooter ref={forwardRef}>
      <div ref={footerObserverRef}>
        {footerVisible && (
          <>
            <ContactUs setIsVisibleModal={setIsVisibleModal} />
            <StyledCopyright>
              <StyledCopyrightText>
                Copyright © 2024. All Rights Reserved.
              </StyledCopyrightText>
            </StyledCopyright>
          </>
        )}
      </div>
    </StyledFooter>
  );
};
