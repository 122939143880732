import styled from "@emotion/styled";
import { breakpointMax, calcRem, colors } from "../../theme";
import { keyframes } from "@emotion/react";

const fadeIn = keyframes`
  0% {
    opacity:0
  }
  100% {
    opacity:1
  }
`;
const floatAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;
const horizontalFloatAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateY(0);
  }
`;

const floatInAnimationRL = keyframes`
  0% {
    transform: translateY(3%);
    transform: translateX(-3%);
  }
  100% {
    transform: translateY(0);
    transform: translateX(0);
    opacity:1
  }
`;
const floatInAnimationLR = keyframes`
  0% {
    transform: translateY(-3%);
    transform: translateX(3%);
  }
  100% {
    transform: translateY(0);
    transform: translateX(0);
    opacity:1
  }
`;
const floatInAnimationText = keyframes`
  0% {
    transform: translateX(-10px);
    opacity:0
  }
  100% {
    transform: translateX(10px);
    opacity:1

  }
`;


export const StyledLanding = styled("div")({});

export const StyledLandingContent = styled("div")({
  width: "80%",
  minHeight: "90vh",
  maxWidth: calcRem(2000),
  margin: `0 auto`,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  zIndex: -1,

  [breakpointMax("xl")]: {
    width: "85%",
  },
  [breakpointMax("lg")]: {
    flexDirection: "column-reverse",
    marginTop: calcRem(40),
    minHeight: "90vh",
  },
});

export const StyledLandingContentMain = styled("div")({
  zIndex: 1,
  flex: 1,

  [breakpointMax("lg")]: {
    flex: 3,
    width: "70%",
    marginTop: calcRem(50),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  [breakpointMax("md")]: {
    flex: 2,
    marginTop: calcRem(0),
    width: "80%",
  },
  [breakpointMax("sm")]: {
    width: "100%",
  },
  [breakpointMax("xs")]: {
    marginTop: calcRem(30),
  },
  [breakpointMax("xxs")]: {
    marginTop: calcRem(10),
  },
});

export const StyledLandingSubHeading = styled("h3")({
  fontSize: calcRem(24),
  color: colors.primary,
  display: "flex",
  alignItems: "center",
  fontWeight: "500",
  animation: `${floatInAnimationText} 1s ease forwards .5s`, 
  opacity: 0,

  "& div": {
    width: calcRem(60),
    height: calcRem(3),
    marginRight: calcRem(20),
    background: colors.primary,
    [breakpointMax("lg")]: {
      display: "none",
    },
  },
  [breakpointMax("xxl")]: {
    fontSize: calcRem(18),
  },
  [breakpointMax("md")]: {
    fontSize: calcRem(16),
  },
  [breakpointMax("xs")]: {
    fontSize: calcRem(16),
  },
  [breakpointMax("xxs")]: {
    fontSize: calcRem(14),
  },
});

export const StyledLandingHeading = styled("h1")({
  fontSize: calcRem(70),
  color: colors.accent,
  fontWeight: "bold",
  margin: 0,
  animation: `${floatInAnimationText} 1s ease forwards .75s`, 
  opacity: 0,
  [breakpointMax("xxl")]: {
    fontSize: calcRem(56),
  },
  [breakpointMax("md")]: {
    fontSize: calcRem(50),
  },
  [breakpointMax("xs")]: {
    fontSize: calcRem(42),
  },
  [breakpointMax("xxs")]: {
    fontSize: calcRem(36),
  },
});

export const StyledLandingDescription = styled("h2")({
  fontSize: calcRem(24),
  color: colors.text,
  fontWeight: "400",
  lineHeight: calcRem(40),
  animation: `${floatInAnimationText} 1s ease forwards 1s`, 
  opacity: 0,
  [breakpointMax("xxl")]: {
    fontSize: calcRem(18),
    lineHeight: calcRem(30),
  },
  [breakpointMax("lg")]: {
    textAlign: "center",
  },
  [breakpointMax("md")]: {
    fontSize: calcRem(16),
  },
  [breakpointMax("xxs")]: {
    fontSize: calcRem(14),
    lineHeight: calcRem(20),
  },
  "& span": {
    fontSize: calcRem(24),
    color: colors.primary,
    fontWeight: "600",
    [breakpointMax("xxl")]: {
      fontSize: calcRem(18),
    },
    [breakpointMax("md")]: {
      fontSize: calcRem(16),
    },
    [breakpointMax("xxs")]: {
      fontSize: calcRem(14),
    },
  },
});

export const StyledLandingButtonContainer = styled("div")({
  marginTop: calcRem(60),
  display: "flex",
  [breakpointMax("xxl")]: {
    marginTop: calcRem(30),
  },
});

export const StyledLandingButton = styled("button")({
  color: colors.white,
  background: colors.primary,
  fontSize: calcRem(18),
  borderRadius: "100px",
  border: "none",
  fontWeight: "600",
  width: calcRem(200),
  height: calcRem(60),
  marginRight: calcRem(30),
  animation: `${floatInAnimationText} 1s ease forwards 1.25s`, 
  opacity: 0,

  "&:hover": {
    cursor: "pointer",
  },
  [breakpointMax("xxl")]: {
    fontSize: calcRem(16),
    width: calcRem(180),
    height: calcRem(50),
  },
  [breakpointMax("md")]: {
    fontSize: calcRem(14),
    width: calcRem(150),
    height: calcRem(40),
    marginRight: calcRem(10),
  },
});

export const StyledLandingButtonContact = styled("div")({
  color: colors.primary,
  background: "transparent",
  fontSize: calcRem(18),
  fontWeight: "600",
  width: calcRem(200),
  height: calcRem(60),
  display: "flex",
  alignItems: "center",
  animation: `${floatInAnimationText} 1s ease forwards 1.5s`, 
  opacity: 0,
  "&:hover": {
    cursor: "pointer",
  },
  "& img": {
    width: "80px",
    margin: 0,
    [breakpointMax("xxl")]: {
      width: "60px",
    },
  },
  "& p": {
    marginLeft: "-15px",
  },
  [breakpointMax("xxl")]: {
    fontSize: calcRem(16),
    width: calcRem(180),
    height: calcRem(50),
  },
  [breakpointMax("md")]: {
    fontSize: calcRem(14),
    width: calcRem(150),
    height: calcRem(40),
  },
});

export const StyledLandingSocial = styled("div")({
  display: "flex",
  marginTop: calcRem(100),
  fontSize: calcRem(16),
  fontWeight: "400",
  color: colors.accent,
  animation: `${floatInAnimationText} 1s ease forwards 1.75s`, 
  opacity: 0,
  [breakpointMax("xxl")]: {
    fontSize: calcRem(14),
  },
  [breakpointMax("md")]: {
    marginTop: calcRem(20),
  },
  [breakpointMax("xs")]: {
    fontSize: calcRem(12),
  },
});

export const StyledLandingSocialItem = styled("div")({
  margin: `0 ${calcRem(4)}`,
});

export const StyledLandingContentVector = styled("div")({
  flex: 1,
  display: "flex",
  justifyContent: "flex-end",
  zIndex: 1,

  [breakpointMax("lg")]: {
    flex: 2,
    justifyContent: "center",
  },
  [breakpointMax("md")]: {
    flex: 1,
    justifyContent: "center",
  },
  "& img": {
    width: "80%",
    animation: `${floatInAnimationLR} 2s ease forwards .75s`, 
    opacity: 0,
    [breakpointMax("xxl")]: {
      width: "90%",
    },
    [breakpointMax("lg")]: {
      height: "40vh",
      justifyContent: "center",
      alignItems: "center",
    },
    [breakpointMax("xs")]: {
      height: "35vh",
    },
    [breakpointMax("xxs")]: {
      height: "40vh",
    },
  },
});

// BACKGROUND

export const StyledLeftImage = styled("img")({
  position: "absolute",
  height: "75vh",
  top: "15vh",
  zIndex: 0,
  animation: `${floatInAnimationRL} 1s ease forwards .5s`, 
  opacity: 0,
  [breakpointMax("xxl")]: {
    height: "70vh",
  },
  [breakpointMax("xl")]: {
    height: "60vh",
  },
  [breakpointMax("sm")]: {
    height: "60vh",
    top: "25vh",
  },
  [breakpointMax("xs")]: {
    height: "50vh",
    top: "25vh",
  },
  [breakpointMax("xxs")]: {
    height: "35vh",
    top: "40vh",
  },
});

export const StyledDottedImage = styled("img")({
  position: "absolute",
  height: "90vh",
  top: 0,
  zIndex: 0,
  animation: `${fadeIn} 1s ease forwards 3s`, 
  opacity: 0,

  [breakpointMax("md")]: {
    left: "-10%",
  },
  [breakpointMax("sm")]: {
    left: "-30%",
  },
  [breakpointMax("xs")]: {
    left: "-50%",
  },
});
export const StyledRoundImage = styled("img")({
  position: "absolute",
  left: "10%",
  top: "8%",
  width: calcRem(200),
  zIndex: 0,
  animation: `${floatAnimation} 3s infinite ease-in-out alternate,${fadeIn} 1s ease forwards 2.5s`,
  opacity: 0,
  [breakpointMax("xxl")]: {
    width: calcRem(150),
  },
  [breakpointMax("sm")]: {
    display: "none",
  },
});

export const StyledSquareImage = styled("img")({
  position: "absolute",
  left: "30%",
  bottom: "10%",
  width: calcRem(200),
  zIndex: 0,
  animation: `${horizontalFloatAnimation} 3s infinite ease-in-out alternate,${fadeIn} 1s ease forwards 2.5s`,
  opacity: 0,
  [breakpointMax("xxl")]: {
    width: calcRem(150),
  },
  [breakpointMax("xl")]: {
    left: "60%",
  },
  [breakpointMax("lg")]: {
    left: "80%",
  },
  [breakpointMax("sm")]: {
    display: "none",
  },
});

export const StyledRightImage = styled("img")({
  position: "absolute",
  height: "82vh",
  top: "0",
  right: 0,
  zIndex: 0,
  animation: `${floatInAnimationLR} 1s ease forwards .5s`, 
  opacity: 0,
  [breakpointMax("xxl")]: {
    height: "75vh",
  },
  [breakpointMax("xl")]: {
    height: "60vh",
  },
  [breakpointMax("lg")]: {
    height: "60vh",
  },
  [breakpointMax("md")]: {
    height: "40vh",
  },
  [breakpointMax("sm")]: {
    height: "40vh",
  },
  [breakpointMax("xs")]: {
    height: "30vh",
  },
  [breakpointMax("xxs")]: {},
});
