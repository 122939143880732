import styled from "@emotion/styled";
import { breakpointMax, calcRem, colors } from "../../theme";
import { keyframes } from "@emotion/react";

const floatInTop = keyframes`
  0% {
    transform: translateY(-10px);
    opacity:0
  }
  100% {
    transform: translateY(0px);
    opacity:1

  }
`;

export const StyledHeading = styled("div")({
  margin: "0 auto",
  maxWidth: calcRem(700),
  [breakpointMax("xxl")]: {
    maxWidth: "60%",
  },
  [breakpointMax("md")]: {
    maxWidth: "70%",
  },
  [breakpointMax("sm")]: {
    maxWidth: "90%",
  },
  [breakpointMax("xs")]: {
    maxWidth: "100%",
  },
});

export const StyledSubtitle = styled("h3")({
  color: colors.primary,
  fontSize: calcRem(20),
  fontWeight: "500",
  margin: 0,
  textAlign: "center",
  animation: `${floatInTop} 1s ease forwards .5s`,
  opacity: 0,
  [breakpointMax("xxxl")]: {
    fontSize: calcRem(18),
  },
  [breakpointMax("xxl")]: {
    fontSize: calcRem(16),
  },

  [breakpointMax("md")]: {
    fontSize: calcRem(14),
  },
});

export const StyledTitle = styled("h2")({
  color: colors.black,
  fontSize: calcRem(50),
  fontWeight: "bold",
  textAlign: "center",
  marginTop: calcRem(10),
  animation: `${floatInTop} 1s ease forwards .75s`,
  opacity: 0,
  [breakpointMax("xxxl")]: {
    fontSize: calcRem(44),
  },
  [breakpointMax("xxl")]: {
    fontSize: calcRem(40),
  },
  [breakpointMax("lg")]: {
    fontSize: calcRem(32),
  },
  [breakpointMax("md")]: {
    fontSize: calcRem(32),
  },
  [breakpointMax("sm")]: {
    fontSize: calcRem(32),
    lineHeight: calcRem(32),
  },
  [breakpointMax("xs")]: {
    fontSize: calcRem(28),
  },
});
