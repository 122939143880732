export const colors = {
  black: "#091D26",
  white: "#fff",
  primary: "#FE7146",
  secondary: "#303360",
  accent: "#00092C",
  text: "#555555",
  border: "#E9E9E9",
  backgroundSilver: "#F5F5FD",
  background2: "#FAEEED",
  light:"#7B7B7B"
};
