import styled from "@emotion/styled";
import { breakpointMax, calcRem, colors } from "../../../theme";
import { keyframes } from "@emotion/react";


const floatInAnimationTB = keyframes`
  0% {
    transform: translateY(-3%);
  }
  100% {
    transform: translateY(0);
    opacity:1
  }
`;

const jumpIn = keyframes`
  0% {
    transform: scale(0.95);
    opacity: 0;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

export const StyledWWD = styled("div")({
  maxWidth: calcRem(1600),
  [breakpointMax("xxxl")]: {
    maxWidth: calcRem(1300),
  },
  [breakpointMax("xxl")]: {
    width: "80%",
  },
  [breakpointMax("lg")]: {},
  [breakpointMax("md")]: {
    width: "90%",
  },
  [breakpointMax("sm")]: {
    width: "90%",
  },
});
export const StyledWWDContent = styled("div")({
  width: "100%",
  minHeight: calcRem(600),
  margin: `${calcRem(100)} auto 0`,
  display: "flex",
  backgroundColor: colors.secondary,
  borderRadius: calcRem(20),
  position: "relative",
  animation: `${jumpIn} .5s ease forwards 1.5s`,
  opacity: 0,

  [breakpointMax("xxxl")]: {
    minHeight: calcRem(500),
  },
  [breakpointMax("xxl")]: {
    minHeight: calcRem(450),
    margin: `${calcRem(80)} auto 0`,
  },
  [breakpointMax("md")]: {
    minHeight: calcRem(400),
  },
  [breakpointMax("sm")]: {
    width: "80%",
    minHeight: calcRem(500),
    padding: `${calcRem(20)} 0`,
  },
  [breakpointMax("xs")]: {
    width: "90%",
    margin: `${calcRem(40)} auto 0`,
  },
  [breakpointMax("xxs")]: {
    width: "100%",
  },
});

export const StyledWWDContentLeft = styled("div")({
  width: "30%",
  position: "relative",
  [breakpointMax("lg")]: {
    width: "40%",
  },
  [breakpointMax("sm")]: {
    width: "90%",
    margin: "auto",
  },
  [breakpointMax("xs")]: {
    width: "100%",
    margin: "auto",
  },
});
export const StyledWWDContentLeftMenu = styled("div")({
  width: "90%",
  height: "90%",
  position: "absolute",
  top: "-30px",
  left: "20px",
  backgroundColor: colors.white,
  borderRadius: calcRem(10),
  zIndex: 2,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
  padding: `${calcRem(20)} 0 `,
  animation: `${jumpIn} .5s ease forwards 1.75s`,
  opacity: 0,
  [breakpointMax("xxl")]: {
    top: "-20px",
  },
  [breakpointMax("sm")]: {
    top: "0px",
    left: "0px",
    width: "100%",
    height: "80%",
    position: "relative",
    backgroundColor: "transparent",
  },
});

interface StyledWWDContentLeftMenuItemTitleProps {
  active?: boolean;
  itemIndex?: any;
}

export const StyledWWDContentLeftMenuItemTitle = styled(
  "div"
)<StyledWWDContentLeftMenuItemTitleProps>(({ itemIndex, active }) => [
  {
    width: "90%",
    height: "70px",
    borderRadius: calcRem(10),
    backgroundColor: active ? colors.primary : colors.background2,
    display: "flex",
    alignItems: "center",
    position: "relative",
    animation: `${floatInAnimationTB} .5s ease forwards ${
      2 + (itemIndex + 1) * 0.1
    }s`,

    opacity: 0,
    [breakpointMax("xxxl")]: {
      height: "60px",
    },
    [breakpointMax("xxl")]: {
      height: "50px",
    },
    [breakpointMax("md")]: {
      height: "45px",
    },
    [breakpointMax("sm")]: {
      height: active ? "50px" : "30px",
      margin: `${calcRem(10)} 0`,
      backgroundColor: active ? colors.primary : "transparent",
    },
    "&:hover": { cursor: "pointer" },
    "& p ": {
      fontWeight: active ? "800" : "700",
      fontSize: calcRem(20),
      color: active ? colors.white : colors.accent,
      margin: 0,
      padding: 0,
      paddingLeft: calcRem(20),
      [breakpointMax("xxxl")]: {
        fontSize: calcRem(18),
        paddingLeft: calcRem(10),
      },
      [breakpointMax("xxl")]: {
        fontSize: calcRem(16),
        paddingLeft: calcRem(10),
      },
      [breakpointMax("md")]: {
        fontSize: calcRem(14),
      },
      [breakpointMax("sm")]: {
        fontSize: calcRem(16),
        paddingLeft: calcRem(20),
        color: colors.white,
      },
    },

    "& img": {
      display: "none",
      transitionDuration: ".3s",
      [breakpointMax("sm")]: {
        display: "block",
        position: "absolute",
        right: "20px",
        transform: active ? "rotate(90deg)" : "rotate(0deg)",
      },
    },
  },
]);

interface StyledWWDContentDescriptionMobileProps {
  open?: boolean;
  firstLoad?: boolean;
}

export const StyledWWDContentDescriptionMobile = styled(
  "div"
)<StyledWWDContentDescriptionMobileProps>(({ open, firstLoad }) => [
  {
    display: "none",

    [breakpointMax("sm")]: {
      display: open ? "block" : "none",
      margin: `0 ${calcRem(20)} ${calcRem(10)}`,
      fontSize: calcRem(13),
      fontWeight: "400",
      color: colors.white,
      padding: `0 ${calcRem(10)}`,
      "& h3": {
        fontSize: calcRem(14),
        fontWeight: "500",
        color: colors.primary,
        textTransform: "uppercase",
        animation: `${floatInAnimationTB} .5s ease forwards ${
          firstLoad ? "2.25s" : "0s" // Longer delay on first load
        }`,
        opacity: 0,
      },
      "& p": {
        animation: `${floatInAnimationTB} .5s ease forwards ${
          firstLoad ? "2.4s" : "0.25s" // Longer delay on first load
        }`,
        opacity: 0,
      },
    },
  },
]);

export const StyledWWDContentCenter = styled("div")({
  width: "35%",
  display: "flex",
  alignItems: "center",

  [breakpointMax("lg")]: {
    width: "60%",
  },
  [breakpointMax("sm")]: {
    display: "none",
  },
});

export const StyledWWDContentCenterContent = styled("div")({
  marginLeft: calcRem(30),
  [breakpointMax("xxl")]: {
    marginLeft: calcRem(20),
  },
  [breakpointMax("lg")]: {
    marginRight: calcRem(20),
  },
});

export const StyledWWDContentSubtitle = styled("h4")({
  color: colors.primary,
  fontSize: calcRem(20),
  fontWeight: "600",
  margin: 0,
  animation: `${floatInAnimationTB} .5s ease forwards 3s`, // Apply animation with delay of 3s
  opacity: 0,
  [breakpointMax("xxxl")]: {
    fontSize: calcRem(18),
  },
  [breakpointMax("xxl")]: {
    fontSize: calcRem(16),
  },
  [breakpointMax("md")]: {
    fontSize: calcRem(14),
    animation: `${floatInAnimationTB} .5s ease forwards 4s`, // Apply animation with delay of 3s
    opacity: 0,
  },
});

export const StyledWWDContentTitle = styled("h3")({
  color: colors.white,
  fontSize: calcRem(40),
  fontWeight: "600",
  margin: `${calcRem(20)} 0`,
  animation: `${floatInAnimationTB} .5s ease forwards 3.25s`, // Apply animation with delay of 3s
  opacity: 0,
  [breakpointMax("xxxl")]: {
    fontSize: calcRem(36),
  },
  [breakpointMax("xxl")]: {
    fontSize: calcRem(32),
  },
  [breakpointMax("md")]: {
    fontSize: calcRem(28),
    animation: `${floatInAnimationTB} .5s ease forwards 4.25s`, // Apply animation with delay of 3s
    opacity: 0,
  },
});

export const StyledWWDContentDescription = styled("h5")({
  color: colors.white,
  fontSize: calcRem(18),
  fontWeight: "400",
  margin: 0,
  lineHeight: calcRem(30),
  animation: `${floatInAnimationTB} .5s ease forwards 3.5s`, // Apply animation with delay of 3s
  opacity: 0,
  [breakpointMax("xxxl")]: {
    fontSize: calcRem(16),
    lineHeight: calcRem(26),
  },
  [breakpointMax("xxl")]: {
    fontSize: calcRem(14),
    lineHeight: calcRem(18),
  },
  [breakpointMax("md")]: {
    fontSize: calcRem(13),
  },
});
export const StyledWWDContentRight = styled("div")({
  marginLeft: calcRem(20),
  width: "35%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [breakpointMax("lg")]: {
    display: "none",
  },
});

export const StyledWWDContentRightIcon = styled("img")({
  width: "95%",
  animation: `${jumpIn} 1s ease forwards 2s`,
  opacity: 0,
  [breakpointMax("xxxl")]: {
    width: "90%",
  },
  [breakpointMax("xxl")]: {
    width: "80%",
  },
});

// BACKGROUND
export const StyledBottomDecoration = styled("img")({
  position: "absolute",
  left: "20%",
  width: "25%",
  bottom: "0%",
  zIndex: 0,
  [breakpointMax("lg")]: {
    left: "30%",
    width: "30%",
  },
  [breakpointMax("sm")]: {
    width: "50%",
  },
});

export const StyledBottomPlane = styled("img")({
  position: "absolute",
  left: "00%",
  bottom: "0%",
  width: "105%",
  zIndex: 0,
  [breakpointMax("sm")]: {
    bottom: "00%",
    width: "200%",
    left: "-100%",
  },
});
