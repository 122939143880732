import styled from "@emotion/styled";
import { breakpointMax, calcRem, colors } from "../../../theme";
import { keyframes } from "@emotion/react";

const showIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const floatInAnimationLR = keyframes`
  0% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
    opacity:1
  }
`;
export const StyledWPWrapper = styled("div")({
  marginTop: calcRem(100),
  width: "100%",
  position: "relative",
  [breakpointMax("xxl")]: {
    marginTop: calcRem(80),
  },
});

export const StyledWP = styled("div")({
  maxWidth: calcRem(1600),
  margin: "auto",
  marginTop: calcRem(270),
  [breakpointMax("xxxl")]: {
    maxWidth: calcRem(1300),
    marginTop: calcRem(150),
  },
  [breakpointMax("xxl")]: {
    width: "80%",
    marginTop: calcRem(100),
  },
  [breakpointMax("lg")]: {
    marginTop: calcRem(70),
  },
  [breakpointMax("md")]: {
    width: "90%",
    marginTop: calcRem(50),
  },
  [breakpointMax("sm")]: {
    width: "90%",
    marginTop: calcRem(20),
  },
});

export const StyledWPTopImage = styled("img")({
  position: "absolute",
  width: "100%",
  marginTop: "-50px",
  animation: `${showIn} 2s ease forwards 1s `,
  opacity: 0,
});

export const StyledWPContent = styled("div")({
  width: "100%",
  display: "flex",
  marginTop: calcRem(150),
  [breakpointMax("xxxl")]: {
    marginTop: calcRem(130),
  },
  [breakpointMax("xxl")]: {
    marginTop: calcRem(100),
  },
  [breakpointMax("xl")]: {
    marginTop: calcRem(100),
  },
  [breakpointMax("lg")]: {
    flexWrap: "wrap",
    marginTop: calcRem(50),
  },
  [breakpointMax("xs")]: {
    flexDirection: "column",
    alignItems: "center",
    marginTop: calcRem(20),
  },
});

interface StyledWPContentItemProps {
  itemIndex?: any;
}

export const StyledWPContentItem = styled("div")<StyledWPContentItemProps>(
  ({ itemIndex }) => [
    {
      animation: `${floatInAnimationLR} 1s ease forwards ${
        1 + (itemIndex + 1) * 0.5
      }s`,
      opacity: 0,
      [breakpointMax("lg")]: {
        width: "50%",
        marginTop: calcRem(40),
      },
      [breakpointMax("xs")]: {
        width: "70%",
        marginTop: calcRem(10),
      },
      [breakpointMax("xxs")]: {
        width: "90%",
        marginTop: calcRem(0),
      },
      ".divider": {
        width: calcRem(60),
        height: calcRem(4),
        backgroundColor: colors.primary,
        [breakpointMax("xs")]: {
          display: "none",
        },
      },
      ".top": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        position: "relative",

        ".number": {
          fontSize: calcRem(72),
          fontWeight: "bold",
          color: colors.primary,
          opacity: 0.2,
          margin: 0,
          marginTop: calcRem(20),

          [breakpointMax("xxl")]: {
            fontSize: calcRem(66),
          },
          [breakpointMax("xl")]: {
            fontSize: calcRem(62),
          },
        },

        img: {
          width: calcRem(200),
          marginLeft: calcRem(80),
          animation: `${showIn} 1s ease forwards ${4 + (itemIndex + 1) * 0.5}s`,
          opacity: 0,
          [breakpointMax("xxxl")]: {
            width: calcRem(180),
            marginLeft: calcRem(70),
          },
          [breakpointMax("xxl")]: {
            width: calcRem(150),
            marginLeft: calcRem(60),
          },
          [breakpointMax("xl")]: {
            width: calcRem(130),
            marginLeft: calcRem(50),
          },
          [breakpointMax("lg")]: {
            display: "none",
          },
          [breakpointMax("xs")]: {
            display: "block",
            position: "absolute",
            transform: "rotate(90deg)",
            marginLeft: "90%",
            marginTop: "120%",
            width: calcRem(150),
          },
          [breakpointMax("xxs")]: {
            display: "block",
            position: "absolute",
            transform: "rotate(90deg)",
            marginLeft: "80%",
            marginTop: "110%",
            width: calcRem(130),
          },
        },

        "& .mobile-title": {
          display: "none",
          padding: 0,
          color: colors.accent,
          fontSize: calcRem(28),
          fontWeight: "600",
          marginLeft: calcRem(30),
          paddingTop: calcRem(20),
          paddingBottom: calcRem(5),
          borderBottom: `solid 5px ${colors.primary}`,
          animation: `${floatInAnimationLR} 1s ease forwards ${
            1.5 + (itemIndex + 1) * 0.5
          }s`,
          opacity: 0,
          [breakpointMax("xs")]: {
            display: "block",
          },
          [breakpointMax("xxs")]: {
            fontSize: calcRem(24),
          },
        },
      },

      h3: {
        color: colors.accent,
        margin: `${calcRem(20)} 0`,
        padding: 0,
        fontSize: calcRem(24),
        fontWeight: "bold",
        letterSpacing: "1px",
        width: "90%",
        animation: `${floatInAnimationLR} 1s ease forwards ${
          1.5 + (itemIndex + 1) * 0.5
        }s`,
        opacity: 0,
        [breakpointMax("xxl")]: {
          fontSize: calcRem(20),
          letterSpacing: "0px",
        },
        [breakpointMax("xl")]: {
          fontSize: calcRem(18),
        },
        [breakpointMax("xs")]: {
          display: "none",
        },
      },

      ".description": {
        width: "85%",
        fontSize: calcRem(16),
        fontWeight: "light",
        lineHeight: calcRem(30),
        animation: `${floatInAnimationLR} 1s ease forwards ${
          1.75 + (itemIndex + 1) * 0.5
        }s`,
        opacity: 0,
        [breakpointMax("xxl")]: {
          fontSize: calcRem(15),
          lineHeight: calcRem(22),
          width: "90%",
        },
        [breakpointMax("xl")]: {
          fontSize: calcRem(14),
          lineHeight: calcRem(22),
        },
        [breakpointMax("xs")]: {
          marginTop: calcRem(10),
          fontSize: calcRem(16),
        },
      },
    },
  ]
);
