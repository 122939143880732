import React, { useState, useEffect } from "react";
import { Home } from "./pages";
import { Loader } from "./components";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return <>{loading ? <Loader /> : <Home />}</>;
}

export default App;
