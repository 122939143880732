import { StyledLoader } from "./Loader.styled";
import { HashLoader } from "react-spinners";
import { colors } from "../../theme";

export const Loader = () => {
  return (
    <StyledLoader>
      <HashLoader size={60} color={colors.primary} />
    </StyledLoader>
  );
};
