import styled from "@emotion/styled";
import { breakpointMax, calcRem, colors } from "../../theme";
import { keyframes } from "@emotion/react";
const bg = require("./assets/bg.png");

const jumpIn = keyframes`
  0% {
    transform: scale(0.95);
    opacity: 0;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const floatInAnimationLR = keyframes`
  0% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
    opacity:1
  }
`;
const showIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
export const StyledOffers = styled("div")({
  minHeight: calcRem(600),
  backgroundColor: colors.backgroundSilver,
  color: colors.black,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  [breakpointMax("md")]: {
    minHeight: calcRem(500),
  },
  [breakpointMax("sm")]: {
    minHeight: calcRem(400),
  },
});

export const StyledOffersContent = styled("div")({
  height: calcRem(460),
  width: calcRem(1400),
  display: "flex",
  alignItems: "center",
  backgroundImage: `url(${bg})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  borderRadius: calcRem(20),
  position: "absolute",
  animation: `${jumpIn} .5s ease forwards 1s`,
  opacity: 0,
  [breakpointMax("xxxl")]: {
    maxWidth: calcRem(1300),
    height: calcRem(400),
  },
  [breakpointMax("xxl")]: {
    width: "80%",
  },
  [breakpointMax("md")]: {
    height: calcRem(300),
  },
  [breakpointMax("sm")]: {
    height: calcRem(250),
    width: "90%",
  },
});

export const StyledOffersLeft = styled("div")({
  flex: 5,
  paddingLeft: calcRem(60),
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  flexDirection: "column",
  [breakpointMax("md")]: {
    flex: 1,
    paddingLeft: calcRem(40),
  },
  [breakpointMax("sm")]: {
    alignItems: "center",
    paddingLeft: calcRem(0),
  },
});

export const StyledOffersRight = styled("div")({
  flex: 4,
  display: "flex",
  justifyContent: "flex-end",
  paddingRight: calcRem(20),
  [breakpointMax("md")]: {
    flex: 0,
  },
  [breakpointMax("sm")]: {
    display: "none",
  },
});

export const StyledOffersImg = styled("img")({
  height: "400px",
  animation: `${showIn} 1.5s ease forwards 3s`,
  opacity: 0,
  [breakpointMax("xxxl")]: {
    height: "350px",
  },
  [breakpointMax("lg")]: {
    height: "300px",
  },
  [breakpointMax("md")]: {
    height: "150px",
    position: "absolute",
    bottom: calcRem(30),
    right: calcRem(30),
  },
});

export const StyledOffersTitle = styled("h4")({
  fontSize: calcRem(16),
  color: colors.primary,
  fontWeight: "700",
  padding: 0,
  margin: 0,
  animation: `${floatInAnimationLR} .5s ease forwards 2s`,
  opacity: 0,
  [breakpointMax("xxxl")]: {
    fontSize: calcRem(14),
  },
  [breakpointMax("lg")]: {
    fontSize: calcRem(13),
  },
  [breakpointMax("md")]: {
    fontSize: calcRem(12),
  },
  [breakpointMax("xs")]: {
    fontSize: calcRem(12),
  },
});

export const StyledOffersDes = styled("h5")({
  fontSize: calcRem(32),
  color: colors.white,
  fontWeight: "600",
  padding: 0,
  margin: `${calcRem(15)} 0 0 0`,
  animation: `${floatInAnimationLR} .5s ease forwards 2.25s`,
  opacity: 0,
  [breakpointMax("xxxl")]: {
    fontSize: calcRem(30),
  },
  [breakpointMax("lg")]: {
    fontSize: calcRem(26),
  },
  [breakpointMax("md")]: {
    fontSize: calcRem(22),
  },
  [breakpointMax("xs")]: {
    fontSize: calcRem(18),
    padding: `0 ${calcRem(15)}`,
    textAlign: "center",
  },
});

export const StyledOfferContact = styled("h5")({
  marginTop: calcRem(40),
  width: "100%",
  animation: `${floatInAnimationLR} .5s ease forwards 2.5s`,
  opacity: 0,
  [breakpointMax("sm")]: {
    marginTop: calcRem(30),
  },
  "& p": {
    color: colors.white,
    fontSize: calcRem(16),
    fontWeight: "bold",
    margin: "0",
    [breakpointMax("xxxl")]: {
      fontSize: calcRem(15),
    },
    [breakpointMax("md")]: {
      fontSize: calcRem(13),
    },
    [breakpointMax("sm")]: {
      textAlign: "center",
    },
    [breakpointMax("xs")]: {
      fontSize: calcRem(12),
    },
  },
  "& div": {
    display: "flex",
    marginTop: calcRem(7),

    [breakpointMax("sm")]: {
      alignItems: "center",
      justifyContent: "center",
    },
    "& div": {
      display: "flex",
      alignItems: "center",
      animation: `${floatInAnimationLR} .5s ease forwards 2.75s`,
      opacity: 0,
      "&:nth-child(2)": {
        marginLeft: calcRem(40),
        animation: `${floatInAnimationLR} .5s ease forwards 3.25s`,
        opacity: 0,
      },
      "& img": {
        width: calcRem(40),
        [breakpointMax("md")]: {
          width: calcRem(30),
        },
      },
      "& p": {
        fontSize: calcRem(16),
        color: colors.white,
        marginLeft: calcRem(15),
        fontWeight: "400",
        letterSpacing: "1px",
        [breakpointMax("xxxl")]: {
          fontSize: calcRem(15),
        },
        [breakpointMax("md")]: {
          fontSize: calcRem(13),
          marginLeft: calcRem(5),
        },
        [breakpointMax("xs")]: {
          fontSize: calcRem(11),
        },
      },
    },
  },
});
