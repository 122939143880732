import React from "react";
import {
  StyledModal,
  StyledModalContent,
  StyledModalTitle,
  StyledModalHeader,
  StyledModalDescription,
  StyledModalButton,
  StyledModalIcon,
} from "./Modal.styled";

const sent = require("./asset/sent.gif");
interface ModalProps {
  setIsVisibleModal?: any;
}
export const Modal = ({ setIsVisibleModal }: ModalProps) => {
  return (
    <StyledModal>
      <StyledModalContent>
        <StyledModalHeader>
          <div></div>
          <div></div>
          <div></div>
        </StyledModalHeader>
        <StyledModalIcon src={sent} alt="PUT SEO HERE" />
        <StyledModalTitle>Thank you for contacting us!</StyledModalTitle>
        <StyledModalDescription>
          We will be in touch and contact you soon.
        </StyledModalDescription>
        <StyledModalButton onClick={() => setIsVisibleModal(false)}>
          Back to Website
        </StyledModalButton>
      </StyledModalContent>
    </StyledModal>
  );
};
