import React from "react";
import {
  StyledLanding,
  StyledLandingContent,
  StyledLandingDescription,
  StyledLandingHeading,
  StyledLandingSubHeading,
  StyledLandingContentMain,
  StyledLandingContentVector,
  StyledLandingSocialItem,
  StyledLandingButton,
  StyledLandingSocial,
  StyledLandingButtonContainer,
  StyledLandingButtonContact,
  StyledLeftImage,
  StyledRightImage,
  StyledDottedImage,
  StyledRoundImage,
  StyledSquareImage,
} from "./Landing.styled";

const contact = require("./assets/contact.png");
const main = require("./assets/main.svg"); // Make sure this path is correct
const leftImage = require("./assets/leftImage.png");
const rightImage = require("./assets/rightImage.png");
const dotted = require("./assets/dotted.svg");
const round = require("./assets/round.svg");
const square = require("./assets/square.svg");

interface LandingProps {
  onClickExplore: () => void;
  onClickContact: () => void;
}

export const Landing: React.FC<LandingProps> = ({
  onClickExplore,
  onClickContact,
}) => {
  return (
    <StyledLanding>
      <StyledLeftImage src={leftImage} alt="PUT SEO HERE" />
      <StyledRightImage src={rightImage} alt="PUT SEO HERE" />
      <StyledDottedImage src={dotted.default} alt="PUT SEO HERE" />
      <StyledRoundImage src={round.default} alt="PUT SEO HERE" />
      <StyledSquareImage src={square.default} alt="PUT SEO HERE" />

      <StyledLandingContent>
        <StyledLandingContentMain>
          <StyledLandingSubHeading>
            <div></div> You are ready to
          </StyledLandingSubHeading>
          <StyledLandingHeading>GROW BUSINESS</StyledLandingHeading>
          <StyledLandingDescription>
            From idea to impactful reality: Step into a new era of digital
            excellence. Transform your business with our innovative{" "}
            <span> web development expertise at Odyssey.</span>
          </StyledLandingDescription>
          <StyledLandingButtonContainer>
            <StyledLandingButton onClick={onClickExplore}>
              Explore Odyssey
            </StyledLandingButton>
            <StyledLandingButtonContact onClick={onClickContact}>
              <img src={contact} alt="PUT SEO HERE" />
              <p>Contact Us</p>
            </StyledLandingButtonContact>
          </StyledLandingButtonContainer>
          <StyledLandingSocial>
            <StyledLandingSocialItem>Facebook </StyledLandingSocialItem> /
            <StyledLandingSocialItem>Instagram </StyledLandingSocialItem> /
            <StyledLandingSocialItem>Linkedin </StyledLandingSocialItem>
          </StyledLandingSocial>
        </StyledLandingContentMain>
        <StyledLandingContentVector>
          {main && <img src={main.default} alt="PUT SEO HERE" />}
        </StyledLandingContentVector>
      </StyledLandingContent>
    </StyledLanding>
  );
};
