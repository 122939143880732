import {
  StyledNavigation,
  StyledNavigationLogo,
} from "./Navigation.styled";

const logo = require("./assets/logo.jpg");

export const Navigation = () => {
  return (
    <StyledNavigation>
      <StyledNavigationLogo src={logo} alt="PUT SEO HERE" />
     {/*  <StyledMenu>
        <StyledMenuItem active>Home</StyledMenuItem>
        <StyledMenuItem>Working Process</StyledMenuItem>
        <StyledMenuItem>About Us</StyledMenuItem>
        <StyledMenuItem>Pricing</StyledMenuItem>
      </StyledMenu>
      <StyledButtonContainer>
        <StyledButtonIcon src={phone} alt="PUT SEO HERE" />
        <StyledButtonText>Contact Us!</StyledButtonText>
      </StyledButtonContainer> */}
    </StyledNavigation>
  );
};
