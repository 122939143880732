import React from "react";
import {
  StyledWP,
  StyledWPTopImage,
  StyledWPContent,
  StyledWPWrapper,
  StyledWPContentItem,
} from "./WP.styled";
import Heading from "../../Heading/Heading";

const WPTopImage = require("./assets/WP_top.png");
const WPArrowImage = require("./assets/WP_arrow.png");

export const WP = () => {
  const data = [
    {
      title: "Discovery",
      description:
        "Explore your business vision in collaborative sessions, shaping the foundation for success.",
    },
    {
      title: "Design",
      description:
        "Transform ideas into compelling designs that captivate and resonate with your audience.",
    },
    {
      title: "Development",
      description:
        "Execute the vision with cutting-edge development, creating robust and scalable solutions.",
    },
    {
      title: "Launch",
      description:
        "Reveal your online marvel, effortlessly shifting from development to a vibrant, impactful existence.",
    },
  ];
  return (
    <StyledWPWrapper>
      <StyledWPTopImage src={WPTopImage} alt="Odyssey Website Development: Boost Your Digital Experiences" />
      <StyledWP>
        <Heading subtitle="WORKING PROCESS?" title="How does it work?" />
        <StyledWPContent>
          {data.map((item, index) => {
            return (
              <StyledWPContentItem itemIndex={index} key={index}>
                <div className="divider"></div>
                <div className="top">
                  <p className="number">{index + 1}</p>
                  {index !== 3 && (
                    <img
                      className={index === 1 ? "second" : ""}
                      src={WPArrowImage}
                      alt="PUT SEO HERE"
                    />
                  )}
                  <p className="mobile-title">{item.title}</p>
                </div>
                <h3>{item.title}</h3>
                <p className="description">{item.description}</p>
              </StyledWPContentItem>
            );
          })}
        </StyledWPContent>
      </StyledWP>
    </StyledWPWrapper>
  );
};
