import styled from "@emotion/styled";
import { breakpointMax, calcRem, colors } from "../../theme";
import { keyframes } from "@emotion/react";

const showIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const floatInAnimationTB = keyframes`
  0% {
    transform: translateY(-3%);
  }
  100% {
    transform: translateY(0);
    opacity:1
  }
`;

const jumpIn = keyframes`
  0% {
    transform: scale(0.95);
    opacity: 0;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;
export const StyledModal = styled("div")({
  minHeight: "100vh",
  width: "100%",
  backgroundColor: "RGBA(123, 123, 123, 0.7)",
  color: colors.black,
  position: "fixed",
  left: 0,
  top: 0,
  zIndex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  animation: `${showIn} 1s ease forwards 0s `,
  opacity: 0,
});

export const StyledModalContent = styled("div")({
  minHeight: "400px",
  width: "600px",
  backgroundColor: colors.white,
  borderRadius: calcRem(10),
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  zIndex: 1,
  flexDirection: "column",
  boxSizing: "content-box",
  animation: `${jumpIn} .5s ease forwards .5s `,
  opacity: 0,
  [breakpointMax("sm")]: {
    minHeight: "350px",
    width: "500px",
  },
  [breakpointMax("xs")]: {
    width: "90%",
  },
});
export const StyledModalHeader = styled("div")({
  width: "100%",
  height: "25px",
  borderBottom: `solid 1px RGBA(123, 123, 123, 0.4)`,
  display: "flex",
  alignItems: "center",
  "& div": {
    width: "10px",
    height: "10px",
    marginLeft: "10px",
    borderRadius: "100px",
    "&:nth-child(1)": { backgroundColor: "#FF5F52", marginLeft: "15px" },
    "&:nth-child(2)": { backgroundColor: "#FFBD2E" },
    "&:nth-child(3)": { backgroundColor: "#27C93F" },
  },
});

export const StyledModalTitle = styled("div")({
  fontSize: calcRem(28),
  color: colors.primary,
  fontWeight: "600",
  animation: `${floatInAnimationTB} .5s ease forwards 1.5s `,
  opacity: 0,
  textAlign:'center',
  [breakpointMax("xl")]: {
    fontSize: calcRem(26),
  },
  [breakpointMax("sm")]: {
    fontSize: calcRem(24),
  },
  [breakpointMax("xs")]: {
    fontSize: calcRem(20),
  },
});

export const StyledModalDescription = styled("div")({
  fontSize: calcRem(14),
  color: colors.secondary,
  fontWeight: "600",
  marginTop: calcRem(15),
  animation: `${floatInAnimationTB} .5s ease forwards 1.75s `,
  opacity: 0,
  textAlign:'center',
  [breakpointMax("sm")]: {
    fontSize: calcRem(13),
  },
  [breakpointMax("xs")]: {
    fontSize: calcRem(12),
  },
});
export const StyledModalButton = styled("div")({
  fontSize: calcRem(18),
  color: colors.white,
  backgroundColor: colors.primary,
  border: "none",
  borderRadius: "10px",
  height: "50px",
  width: "200px",
  fontWeight: "600",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: calcRem(30),
  animation: `${floatInAnimationTB} .5s ease forwards 2s `,
  opacity: 0,
  "&:hover": {
    cursor: "pointer",
  },
  [breakpointMax("md")]: {
    fontSize: calcRem(16),
  },
  [breakpointMax("sm")]: {
    marginTop: calcRem(40),
  },
  [breakpointMax("xs")]: {
    marginTop: calcRem(40),
  },
});
export const StyledModalIcon = styled("img")({
  width: calcRem(70),
  margin: `${calcRem(50)} 0`,
  animation: `${showIn} 1s ease forwards 3s `,
  opacity: 0,
  [breakpointMax("sm")]: {
    margin: `${calcRem(30)} 0`,
  },
});
