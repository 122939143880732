import React from "react";
import { StyledHeading, StyledSubtitle, StyledTitle } from "./Heading.styled";
interface HeadingProps {
  title: string;
  subtitle: string;
}
const Heading = ({ title, subtitle }: HeadingProps) => (
  <StyledHeading>
    <StyledSubtitle>{subtitle}</StyledSubtitle>
    <StyledTitle>{title}</StyledTitle>
  </StyledHeading>
);

export default Heading;
