import styled from "@emotion/styled";
import { breakpointMax, calcRem, colors } from "../../theme";
import { keyframes } from "@emotion/react";
const showIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
export const StyledAboutUs = styled("div")({
  minHeight: "100vh",
  backgroundColor: colors.backgroundSilver,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  padding: ` ${calcRem(100)} 0 0`,
  animation: `${showIn} .5s ease forwards `,
  opacity: 0,
  [breakpointMax("sm")]: {
    padding: ` ${calcRem(80)} 0 0`,
  },
  [breakpointMax("xs")]: {
    padding: ` ${calcRem(60)} 0 0`,
  },
  "& .wrapper": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    minHeight: "30vh",
  },
});
