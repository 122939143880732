import React, { useRef, useState } from "react";
import { StyledHome } from "./Home.styled";
import { Navigation, Offers, Modal } from "../../components";
import { Landing, AboutUs, Footer } from "../../views";

export const Home = () => {
  const aboutUsRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const scrollToExplore = () => {
    aboutUsRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToContact = () => {
    footerRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <StyledHome>
      <Navigation />
      <Landing
        onClickExplore={scrollToExplore}
        onClickContact={scrollToContact}
      />
      <AboutUs forwardRef={aboutUsRef} />
      <Offers />
      <Footer forwardRef={footerRef} setIsVisibleModal={setIsVisibleModal} />
      {isVisibleModal && <Modal setIsVisibleModal={setIsVisibleModal} />}
    </StyledHome>
  );
};

export default Home;
