import React, { useState, useEffect } from "react";
import { StyledAboutUs } from "./AboutUs.styled";
import { WP, WWD } from "../../components";
import { useInView } from "react-intersection-observer";

interface AboutUsProps {
  forwardRef: React.RefObject<HTMLDivElement>;
}

export const AboutUs = ({ forwardRef }: AboutUsProps) => {
  const [wwdVisible, setWwdVisible] = useState(false);
  const [wpVisible, setWpVisible] = useState(false);

  const [wwdObserverRef, wwdInView] = useInView({
    threshold: 0.5,
  });

  const [wpObserverRef, wpInView] = useInView({
    threshold: 0.7, // Adjusted threshold for WP
  });

  useEffect(() => {
    if (wwdInView && !wwdVisible) {
      setTimeout(() => {
        setWwdVisible(true);
      }, 200);
    }
  }, [wwdInView, wwdVisible]);

  useEffect(() => {
    if (wpInView && !wpVisible) {
      setTimeout(() => {
        setWpVisible(true);
      }, 200);
    }
  }, [wpInView, wpVisible]);

  return (
    <StyledAboutUs ref={forwardRef}>
      <div className="wrapper" ref={wwdObserverRef}>
        {wwdVisible && <WWD />}
      </div>
      <div className="wrapper" ref={wpObserverRef}>
        {wpVisible && <WP />}
      </div>
    </StyledAboutUs>
  );
};
