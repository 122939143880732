import { useState, useEffect } from "react";
import {
  StyledWWD,
  StyledWWDContent,
  StyledWWDContentLeft,
  StyledWWDContentLeftMenu,
  StyledWWDContentCenter,
  StyledWWDContentCenterContent,
  StyledWWDContentSubtitle,
  StyledWWDContentTitle,
  StyledWWDContentDescription,
  StyledWWDContentRight,
  StyledWWDContentRightIcon,
  StyledBottomDecoration,
  StyledWWDContentLeftMenuItemTitle,
  StyledBottomPlane,
  StyledWWDContentDescriptionMobile,
} from "./WWD.styled";
import Heading from "../../Heading/Heading";
import { data } from "./data";

const WWDIcon = require("./assets/WWD_icon.png");
const WWDDecoration = require("./assets/WWD_decoration.png");
const WWDPlane = require("./assets/WWD_plane.png");
const WWDArrow = require("./assets/WWD_arrow.png");
interface MenuItemProps {
  title: string;
  subtitle: string;
  description: string;
}

export const WWD = () => {
  const [activeItem, setActiveItem] = useState(data[0]);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    setFirstLoad(false);
  }, []);
  return (
    <StyledWWD>
      <Heading
        subtitle="WHAT WE DO?"
        title="We provide wide range of business services"
      />
      <StyledWWDContent>
        <StyledWWDContentLeft>
          <StyledWWDContentLeftMenu>
            {data.map((item: MenuItemProps, index: any) => {
              return (
                <>
                  <StyledWWDContentLeftMenuItemTitle
                    key={index}
                    onClick={() => setActiveItem(item)}
                    active={item.title === activeItem.title}
                    itemIndex={index}
                  >
                    <p>
                      0{index + 1}. {item.title}
                    </p>
                    <img src={WWDArrow} alt="PUT SEO HERE" />
                  </StyledWWDContentLeftMenuItemTitle>
                  <StyledWWDContentDescriptionMobile
                    open={item.title === activeItem.title}
                    firstLoad={firstLoad}
                  >
                    <h3>{item.subtitle}</h3>
                    <p> {item.description}</p>
                  </StyledWWDContentDescriptionMobile>
                </>
              );
            })}
          </StyledWWDContentLeftMenu>
        </StyledWWDContentLeft>
        <StyledWWDContentCenter>
          <StyledWWDContentCenterContent>
            <StyledWWDContentSubtitle>
              {activeItem.subtitle}
            </StyledWWDContentSubtitle>
            <StyledWWDContentTitle> {activeItem.title}</StyledWWDContentTitle>
            <StyledWWDContentDescription>
              {activeItem.description}
            </StyledWWDContentDescription>
          </StyledWWDContentCenterContent>
        </StyledWWDContentCenter>
        <StyledWWDContentRight>
          <StyledWWDContentRightIcon src={WWDIcon} alt="PUT SEO HERE" />
        </StyledWWDContentRight>
        <StyledBottomDecoration src={WWDDecoration} alt="PUT SEO HERE" />
        <StyledBottomPlane src={WWDPlane} alt="PUT SEO HERE" />
      </StyledWWDContent>
    </StyledWWD>
  );
};
